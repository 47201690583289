package id.gaenta.lp.components.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.AlignContent
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors.Orange
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import id.gaenta.lp.components.widgets.IconButton
import id.gaenta.lp.components.widgets.SocialIcon
import id.gaenta.lp.styles.SocialIconStyle
import id.gaenta.lp.util.Res
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span

val FooterStyle = CssStyle.base {
    Modifier
        .backgroundColor(
            if (colorMode.isLight) Res.Theme.GRADIENT_TWO.color
            else Res.Theme.GRADIENT_TWO_DARK.color
        )
        .padding(
            topBottom = 1.0.cssRem,
            leftRight = 3.percent
        )
}

@Composable
fun FooterSide(modifier: Modifier = Modifier) {
    val colorMode by ColorMode.currentState
    val breakpoint = rememberBreakpoint()

    Box(FooterStyle.toModifier().then(modifier)) {
        if (breakpoint <= Breakpoint.MD) {
            /*Footer MobileBrowser*/
            SimpleGrid(
                numColumns = numColumns(1, md = 3),
                modifier = Modifier
                    .align(Alignment.Center)
//                    .align(alignment = Alignment.Center)
                    .padding(all = 2.px)
                    .background(
                        if (colorMode.isLight) Res.Theme.GRADIENT_TWO.color
                        else Res.Theme.GRADIENT_TWO_DARK.color
                    )
            ) {
                Box(
                    contentAlignment = Alignment.BottomStart
                ) {
                    Span(Modifier
                        .fontSize(13.px)
                        .align(Alignment.Center)
                        .toAttrs()) {
                        Row(
                            modifier = Modifier.fillMaxWidth().gap(8.px),
                            horizontalArrangement = if (breakpoint <= Breakpoint.SM) Arrangement.Center else Arrangement.Start
                        ) {
                            SocialIcon.entries.filter {
                                if (colorMode.isLight) !it.name.contains("Light")
                                else it.name.contains("Light")
                            }.forEach {
                                IconButton(
                                    modifier = SocialIconStyle.toModifier(),
                                    colorMode = colorMode,
                                    icon = it.icon,
                                    link = it.link
                                )
                            }
                        }
                    }
                }

                Box(
                    contentAlignment = Alignment.BottomCenter
                ) {
                    Span(Modifier
                        .fontSize(13.px)
                        .align(Alignment.Center)
                        .toAttrs()) {
                        Link(
                            "/privacy-policy",
                            variant = UncoloredLinkVariant,
                            modifier = Modifier.fontFamily(Res.String.POPPINS_REGULAR),
                            text = Res.String.PRIVACY_POLICY
                        )

                        SpanText(" | ")

                        Link(
                            "/site-map",
                            variant = UncoloredLinkVariant,
                            modifier = Modifier.fontFamily(Res.String.POPPINS_REGULAR),
                            text = Res.String.SITE_MAP
                        )
                    }
                }

                Box(
                    contentAlignment = Alignment.BottomEnd
                ) {
                    Span(
                        Modifier
                            .fontSize(13.px)
                            .align(Alignment.Center)
                            .toAttrs()
                    ) {
                        SpanText(
                            modifier = Modifier.fontFamily(Res.String.POPPINS_REGULAR),
                            text = Res.String.COPYRIGHT
                        )
                    }
                }
            }
        } else {
            /*Footer DesktopLaptopBrowser*/
            SimpleGrid(
                numColumns = numColumns(3, md = 3),
                modifier = Modifier
                    .alignContent(AlignContent.End)
                    .fillMaxSize()
                    .height(Res.Dimens.MAX_FOOTER_HEIGHT.px)
                    .padding(all = 2.px)
                    .borderRadius(r = Res.Dimens.BORDER_FOOTER_RADIUS.px)
                    .background(
                        if (colorMode.isLight) Res.Theme.GRADIENT_TWO.color
                        else Res.Theme.GRADIENT_TWO_DARK.color
                    )
            ) {
                Box(
                    contentAlignment = Alignment.CenterStart
                ) {
                    Span(
                        Modifier
                            .fontSize(13.px)
                            .textAlign(TextAlign.Center)
                            .toAttrs()
                    ) {
                        SpanText(
                            modifier = Modifier.fontFamily(Res.String.POPPINS_REGULAR),
                            text = Res.String.COPYRIGHT
                        )
                    }
                }

                Box(
                    contentAlignment = Alignment.Center
                ) {
                    Span(Modifier.fontSize(13.px).textAlign(TextAlign.Center).toAttrs()) {
                        Link(
                            "/privacy-policy",
                            variant = UncoloredLinkVariant,
                            modifier = Modifier.fontFamily(Res.String.POPPINS_REGULAR),
                            text = Res.String.PRIVACY_POLICY
                        )

                        SpanText(" | ")

                        Link(
                            "/site-map",
                            variant = UncoloredLinkVariant,
                            modifier = Modifier.fontFamily(Res.String.POPPINS_REGULAR),
                            text = Res.String.SITE_MAP
                        )
                    }
                }

                Box(
                    contentAlignment = Alignment.CenterEnd
                ) {
                    Span(Modifier.fontSize(13.px).textAlign(TextAlign.Center).toAttrs()) {
                        SpanText(
                            modifier = Modifier.opacity(90.percent).lineHeight(2).color(
                                if (colorMode.isLight) Orange
                                else Orange
                            ).fontWeight(FontWeight.Bold).fontFamily(Res.String.POPPINS_REGULAR),
                            text = Res.String.STAY_IN_TOUCH
                        )

                        Row(
                            modifier = Modifier.fillMaxWidth().gap(8.px),
                            horizontalArrangement = if (breakpoint <= Breakpoint.SM) Arrangement.Center else Arrangement.Start
                        ) {
                            SocialIcon.entries.filter {
                                if (colorMode.isLight) !it.name.contains("Light")
                                else it.name.contains("Light")
                            }.forEach {
                                IconButton(
                                    modifier = SocialIconStyle.toModifier(),
                                    colorMode = colorMode,
                                    icon = it.icon,
                                    link = it.link
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}