package id.gaenta.lp.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.AlignContent
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.CloseIcon
import com.varabyte.kobweb.silk.components.icons.HamburgerIcon
import com.varabyte.kobweb.silk.components.icons.MoonIcon
import com.varabyte.kobweb.silk.components.icons.SunIcon
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.overlay.OverlayVars
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.breakpoint.displayUntil
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import id.gaenta.lp.components.widgets.IconButton
import id.gaenta.lp.util.Res
import org.jetbrains.compose.web.css.*

val HeaderSideStyle = CssStyle.base {
    Modifier.fillMaxWidth().padding(1.cssRem)
}

@Composable
private fun NavLink(path: String, text: String) {
    Link(path, text, variant = UndecoratedLinkVariant.then(UncoloredLinkVariant))
}

@Composable
private fun MenuItems() {
    NavLink("/", Res.String.HOME)
    NavLink("/products", Res.String.PRODUCTS)
    NavLink("/solutions", Res.String.SOLUTIONS)
    NavLink("/support", Res.String.SUPPORT)
    NavLink("/gaenta", Res.String.GAENTA)
}

@Composable
private fun ColorModeButton() {
    var colorMode by ColorMode.currentState
    IconButton(onClick = { colorMode = colorMode.opposite }) {
        if (colorMode.isLight) MoonIcon() else SunIcon()
    }
    Tooltip(ElementTarget.PreviousSibling, "Toggle color mode", placement = PopupPlacement.BottomRight)
}

@Composable
private fun HamburgerButton(onClick: () -> Unit) {
    IconButton(onClick) {
        HamburgerIcon()
    }
}

@Composable
private fun CloseButton(onClick: () -> Unit) {
    IconButton(onClick) {
        CloseIcon()
    }
}

val SideMenuSlideInAnim = Keyframes {
    from {
        Modifier.translateX(100.percent)
    }

    to {
        Modifier
    }
}

enum class SideMenuState {
    CLOSED,
    OPEN,
    CLOSING;

    fun close() = when (this) {
        CLOSED -> CLOSED
        OPEN -> CLOSING
        CLOSING -> CLOSING
    }
}

/*Main Code*/
@Composable
fun HeaderSide() {
    val colorMode by ColorMode.currentState
    val breakpoint = rememberBreakpoint()

    Row(HeaderSideStyle.toModifier(), verticalAlignment = Alignment.CenterVertically) {
        if (breakpoint <= Breakpoint.MD) {
            /*Menu MobileBrowser*/
            Link(Res.String.LINK_GAENTA) {
                if (colorMode.isLight)
                    Image(
                        "/logos/logo-gaenta-only_color.png",
                        "Gaenta Logo",
                        Modifier.height(2.cssRem).display(DisplayStyle.Block)
                    )
                else
                    Image(
                        "/logos//logo-gaenta_broken-white.png",
                        "Gaenta Logo",
                        Modifier.height(2.cssRem).display(DisplayStyle.Block)
                    )
            }

            Spacer()

            Row(
                Modifier
                    .fontFamily(Res.String.POPPINS_REGULAR)
                    .fontSize(1.5.cssRem)
                    .gap(1.cssRem)
                    .displayUntil(Breakpoint.MD),
                verticalAlignment = Alignment.CenterVertically
            ) {
                var menuState by remember { mutableStateOf(SideMenuState.CLOSED) }

                HamburgerButton(onClick = { menuState = SideMenuState.OPEN })

                if (menuState != SideMenuState.CLOSED) {
                    SideMenu(
                        menuState,
                        close = { menuState = menuState.close() },
                        onAnimationEnd = {
                            if (menuState == SideMenuState.CLOSING) menuState = SideMenuState.CLOSED
                        }
                    )
                }
            }
        } else {
            /*Menu DesktopBrowser*/
            SimpleGrid(
                numColumns = numColumns(3, md = 3),
                modifier = Modifier
                    .alignContent(AlignContent.Center)
                    .fillMaxSize()
                    .padding(leftRight = 250.px)
            ) {
                Box(
                    contentAlignment = Alignment.CenterStart,
                    modifier = Modifier
                        .fillMaxWidth(30.px)
                ) {
                    Link(Res.String.LINK_GAENTA) {
                        if (colorMode.isLight)
                            Image(
                                "/logos//logo-gaenta-only_color.png",
                                "Gaenta Logo",
                                Modifier.height(2.cssRem).display(DisplayStyle.Block)
                            )
                        else
                            Image(
                                "/logos//logo-gaenta_broken-white.png",
                                "Gaenta Logo",
                                Modifier.height(2.cssRem).display(DisplayStyle.Block)
                            )
                    }
                }

                Box(
                    contentAlignment = Alignment.Center,
                    modifier = Modifier
                        .fillMaxSize()
                ) {
                    Row(
                        Modifier
                            .fontFamily(Res.String.POPPINS_REGULAR)
                            .gap(1.5.cssRem)
                            .displayIfAtLeast(Breakpoint.MD),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        MenuItems()
                    }
                }

                Box(
                    contentAlignment = Alignment.CenterEnd,
                    modifier = Modifier
                        .fillMaxWidth(30.px)
                ) {
//                    ColorModeButton()
                }
            }
        }
    }
}

@Composable
private fun SideMenu(menuState: SideMenuState, close: () -> Unit, onAnimationEnd: () -> Unit) {
    val colorMode by ColorMode.currentState

    Overlay(
        Modifier
            .setVariable(OverlayVars.BackgroundColor, Colors.Transparent)
            .onClick { close() }
    ) {
        key(menuState) { // Force recompute animation parameters when close button is clicked
            Column(
                Modifier
                    .fontFamily(Res.String.POPPINS_REGULAR)
                    .fillMaxHeight()
                    .width(clamp(8.cssRem, 33.percent, 10.cssRem))
                    .align(Alignment.CenterEnd)
                    .padding(top = 1.cssRem, leftRight = 1.cssRem)
                    .gap(1.5.cssRem)
                    .backgroundColor(
                        if (colorMode.isLight) Res.Theme.GRADIENT_TWO.color
                        else Res.Theme.GRADIENT_ONE_DARK.color
                    )
                    .animation(
                        SideMenuSlideInAnim.toAnimation(
                            duration = 200.ms,
                            timingFunction = if (menuState == SideMenuState.OPEN) AnimationTimingFunction.EaseOut else AnimationTimingFunction.EaseIn,
                            direction = if (menuState == SideMenuState.OPEN) AnimationDirection.Normal else AnimationDirection.Reverse,
                            fillMode = AnimationFillMode.Forwards
                        )
                    )
                    .borderRadius(topLeft = 2.cssRem)
                    .onClick { it.stopPropagation() }
                    .onAnimationEnd { onAnimationEnd() },
                horizontalAlignment = Alignment.End
            ) {
                CloseButton(onClick = { close() })
                Column(
                    Modifier.padding(right = 0.75.cssRem).gap(1.5.cssRem).fontSize(1.4.cssRem),
                    horizontalAlignment = Alignment.End
                ) {
                    MenuItems()
                }
            }
        }
    }
}
