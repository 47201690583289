package id.gaenta.lp.util

import com.varabyte.kobweb.compose.ui.graphics.Color

object Res {
    enum class Theme(val color: Color) {
        LIGHT_BLUE(color = Color.rgb(r = 250, g = 249, b = 247)),
        DARK_BLUE(color = Color.rgb(r = 12, g = 9, b = 10)),
        SOCIAL_ICON_BACKGROUND_LIGHT(color = Color.rgb(r = 235, g = 245, b = 251)),
        SOCIAL_ICON_BACKGROUND_DARK(color = Color.rgb(r = 46, g = 134, b = 193)),
        GRADIENT_ONE(color = Color.rgb(r = 255, g = 255, b = 255)),
        GRADIENT_TWO(color = Color.rgb(r = 248, g = 247, b = 243)),
        GRADIENT_ONE_DARK(color = Color.rgb(r = 20, g = 46, b = 73)),
        GRADIENT_TWO_DARK(color = Color.rgb(r = 12, g = 9, b = 10))
    }

    object Icon {
        const val EMAIL = "/icons/email_bg-blue.svg"
        const val EMAIL_LIGHT = "/icons/email_bg-white.svg"
        const val WHATSAPP = "/icons/whatsapp.svg"
        const val WHATSAPP_LIGHT = "/icons/whatsapp_white.svg"
        const val LINKEDIN = "/icons/linkedin.svg"
        const val LINKEDIN_LIGHT = "/icons/linkedin_white.svg"
        const val FACEBOOK = "/icons/facebook.svg"
        const val FACEBOOK_LIGHT = "/icons/facebook_white.svg"
        const val INSTAGRAM = "/icons/instagram.svg"
        const val INSTAGRAM_LIGHT = "/icons/instagram_white.svg"
        const val X = "/icons/x-twitter.svg"
        const val X_LIGHT = "/icons/x-twitter_white.svg"
        const val GMAPS = "/icons/google-map_color.svg"
        const val GMAPS_LIGHT = "/icons/google-map_white.svg"
        const val SUN = "sun.svg"
        const val MOON = "moon.svg"
    }

    object Image {
        const val C_IMAGE_PRODUCT = "/images/slide-products.png"
        const val C_IMAGE_SOLUTION = "/images/slide-solution.png"
        const val C_IMAGE_OTHER = "/images/slide-other.png"
    }

    object String {
        /*General String*/
        const val COMPANY_FULL = "PT. Gaenta Sinergi Sukses"
        const val CALL_NAME_1 = "Gaenta"
        const val CALL_NAME_2 = "GSS"

        /**/
        const val MAIL_ADDRESS_MARKETING = "marketing@gaenta.id"
        const val MAIL_ADDRESS_PRODUCTS = "products@gaenta.id"
        const val MAIL_ADDRESS_SOLUTIONS = "solutions@gaenta.id"
        const val MAIL_ADDRESS_GET_HELP = "hello@gaenta.id"
        const val WHATSAPP_NUMBER = "628818228339"
        const val WHATSAPP_LINK = "https://wa.me/628818228339"

        /*Home*/
        const val COMPANY_CALL_NAME = "Gaenta"
        const val TAGLINE = "\"Support You Can Trust, Partner in IT Excellence and We Make IT Work for You.\""
        const val QUOTES =
            "\"The true potential of any system is unlocked when it becomes an extension of human capabilities, not a replacement for them.\" —Naveen Jain"
        const val GET_IN_TOUCH = "Get in touch:"

        /*HeaderSide*/
        const val HOME = "Home"
        const val PRODUCTS = "Products"
        const val SOLUTIONS = "Solutions"
        const val SUPPORT = "Support"
        const val GAENTA = "Gænta"

        /*Products Pages*/

        /*Solutions Pages*/

        /*Support Pages*/
        const val GET_SUPPORT = "Get Support"
        const val GET_SUPPORT_DESC = "Give us a few details and we’ll offer the best information."
        const val PRODUCT_SUPPORT = "Product Support"
        const val PRODUCT_SUPPORT_DESC = "Get help for all $CALL_NAME_1 Products and we’ll offer the best solution."
        const val SOLUTION_SUPPORT = "Solution Support"
        const val SOLUTION_SUPPORT_DESC = "Get help for all $CALL_NAME_2 Solutions and we’ll offer the best solution."

        /*About Us Pages*/
        const val ABOUT_US = "About Us"
        const val VISION = "Being a leading information technology company by offering best products and services"
        const val MISSION = "Technology-based products with the aim of providing solutions for customers"
        const val VALUES_1 = "Continuous innovation"
        const val VALUES_2 = "Effectiveness and Efficiency"
        const val VALUES_3 = "Quality and Professionalism"
        const val VALUES_4 = "Commitment and Consistent in each service"
        const val CONTACT_US = "Contact Us"
        const val LOCATIONS = "Locations"
        const val LOC_JAKARTA = "Jakarta"
        const val LOC_JAKARTA_GMAPS = "https://maps.app.goo.gl/5kw1GjUz5eQZ6YUM6"
        const val LOC_JAKARTA_STREET = "Menara 165 lantai 14 Unit E, Jl. TB Simatupang No.Kav. 1, Jakarta Selatan"
        const val LOC_BANDUNG = "Bandung"
        const val LOC_BANDUNG_GMAPS = "https://maps.app.goo.gl/pBv14an9v9U9TABW7"
        const val LOC_BANDUNG_STREET = "Komplek Astom No E-1 lantai 2, Jl. Cilengkrang 1, Kota Bandung"

        /*FooterSide*/
        const val COPYRIGHT = "Copyright © 2024 GSS"
        const val PRIVACY_POLICY = "Privacy Policy"
        const val SITE_MAP = "Site Map"
        const val STAY_IN_TOUCH = "Stay in touch:"

        /*FontCalls*/
        const val GENOS_SEMIBOLD = "GenosSemiBold"
        const val GENOS_REGULAR = "GenosRegular"
        const val POPPINS_REGULAR = "PoppinsRegular"
        const val COURIER_PRIME_REGULAR = "CourierPrimeRegular"

        /*OtherCalls*/
        const val SAVED_THEME = "theme"
        const val EMAIL_GAENTA_MARKETING = "mailto:$MAIL_ADDRESS_MARKETING"
        const val EMAIL_GAENTA_PRODUCTS = "mailto:$MAIL_ADDRESS_PRODUCTS"
        const val EMAIL_GAENTA_SOLUTIONS = "mailto:$MAIL_ADDRESS_SOLUTIONS"
        const val EMAIL_GAENTA_GET_HELP = "mailto:$MAIL_ADDRESS_GET_HELP"
        const val LINK_GAENTA = "https://gaenta.id"

        /*Coming Soon*/
        const val COMING_SOON_1 = "Coming soon! Please refer to official "
        const val COMING_SOON_2 = " until this page is populated."
    }

    object Dimens {
        const val BORDER_RADIUS = 16
        const val MAX_CARD_WIDTH = 1000
        const val MAX_CARD_HEIGHT = 500
        const val ICON_SIZE = 16
        const val ICON_SIZE_LG = 24
        const val MAX_FOOTER_HEIGHT = 50
        const val BORDER_FOOTER_RADIUS = 5
    }
}