package id.gaenta.lp.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import id.gaenta.lp.components.widgets.GetInTouchIcon
import id.gaenta.lp.components.widgets.IconButton
import id.gaenta.lp.styles.GetInTouchIconStyle
import id.gaenta.lp.util.Res
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span

@Composable
fun LeftSide(
    colorMode: ColorMode,
    breakpoint: Breakpoint
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(all = 40.px),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = if (breakpoint <= Breakpoint.SM)
            Alignment.CenterHorizontally else Alignment.Start
    ) {
        SpanText(
            text = Res.String.COMPANY_CALL_NAME,
            modifier = Modifier
                .margin(bottom = 2.px)
                .fontFamily(Res.String.GENOS_SEMIBOLD)
                .color(
                    if (colorMode.isLight) Colors.Black
                    else Colors.WhiteSmoke
                )
                .fontSize(50.px)
                .fontWeight(FontWeight.Bold)
                .textAlign(
                    if (breakpoint <= Breakpoint.SM) TextAlign.Center
                    else TextAlign.Start
                )
        )

        SpanText(
            text = Res.String.TAGLINE,
            modifier = Modifier
                .margin(bottom = 24.px)
                .fontFamily(Res.String.GENOS_REGULAR)
                .color(
                    if (colorMode.isLight) Colors.Black
                    else Colors.WhiteSmoke
                )
                .fontSize(21.px)
        )

        Surface(
            modifier = Modifier
                .height(4.px)
                .width(40.px)
                .margin(bottom = 24.px)
                .background(
                    if (colorMode.isLight) Colors.Black
                    else Colors.WhiteSmoke
                )
                .align(
                    if (breakpoint <= Breakpoint.SM) Alignment.CenterHorizontally
                    else Alignment.Start
                )
        ) {}

        SpanText(
            text = Res.String.QUOTES,
            modifier = Modifier
                .fontFamily(Res.String.POPPINS_REGULAR)
                .fontSize(14.px)
                .color(
                    if (colorMode.isLight) Colors.Black
                    else Colors.WhiteSmoke
                )
                .opacity(75.percent)
                .lineHeight(2)
                .margin(bottom = 36.px)
                .textAlign(
                    if (breakpoint <= Breakpoint.SM) TextAlign.Center
                    else TextAlign.Start
                )
        )

        /*Mail*/
        Box(
            contentAlignment = Alignment.CenterEnd
        ) {
            Span(Modifier.fontSize(13.px).textAlign(TextAlign.Left).toAttrs()) {
                SpanText(
                    modifier = Modifier
                        .fontSize(14.px)
                        .opacity(90.percent)
                        .lineHeight(2)
                        .color(
                            if (colorMode.isLight) Colors.Orange
                            else Colors.Orange
                        )
                        .fontWeight(FontWeight.Bold)
                        .fontFamily(Res.String.POPPINS_REGULAR),
                    text = Res.String.GET_IN_TOUCH
                )

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .gap(8.px),
                    horizontalArrangement = if (breakpoint <= Breakpoint.SM)
                        Arrangement.Center else Arrangement.Start
                ) {
                    GetInTouchIcon.entries.filter {
                        if (colorMode.isLight) !it.name.contains("Light")
                        else it.name.contains("Light")
                    }.forEach {
                        IconButton(
                            modifier = GetInTouchIconStyle.toModifier(),
                            colorMode = colorMode,
                            icon = it.icon,
                            link = it.link
                        )
                    }
                }
            }
        }
    }
}
