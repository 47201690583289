package id.gaenta.lp.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import id.gaenta.lp.util.Res
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span

@Composable
fun Solutions(colorMode: ColorMode) {
    val breakpoint = rememberBreakpoint()
    SimpleGrid(
        numColumns = numColumns(base = 0, md = 1),
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint <= Breakpoint.MD) 100.percent
                else Res.Dimens.MAX_CARD_WIDTH.px
            )
            .thenIf(
                condition = breakpoint > Breakpoint.MD,
                other = Modifier.height(95.percent)
            )
            .boxShadow(
                color = Colors.Black.copy(alpha = 10),
                blurRadius = 50.px,
                spreadRadius = 50.px
            )
            .padding(all = 12.px)
            .borderRadius(r = Res.Dimens.BORDER_RADIUS.px)
            .background(
                if (colorMode.isLight)
                    Res.Theme.LIGHT_BLUE.color
                else
                    Res.Theme.DARK_BLUE.color
            )
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(all = 40.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = if (breakpoint <= Breakpoint.SM)
                Alignment.CenterHorizontally else Alignment.Start
        ) {
            /*Solutions Title*/
            SpanText(
                text = Res.String.SOLUTIONS,
                modifier = Modifier
                    .margin(bottom = 2.px)
                    .fontFamily(Res.String.POPPINS_REGULAR)
                    .color(
                        if (colorMode.isLight) Colors.Black
                        else Colors.WhiteSmoke
                    )
                    .fontSize(30.px)
                    .fontWeight(FontWeight.Bold)
                    .textAlign(
                        if (breakpoint <= Breakpoint.SM) TextAlign.Center
                        else TextAlign.Start
                    )
            )

            /*Solutions Content*/
            Span {
                SpanText(
                    text = Res.String.COMING_SOON_1,
                    modifier = Modifier
                        .margin(bottom = 10.px)
                        .fontFamily(Res.String.GENOS_REGULAR)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .fontSize(25.px)
                )

                Link(
                    "/support",
                    Res.String.SUPPORT,
                    Modifier
                        .whiteSpace(WhiteSpace.NoWrap)
                        .fontFamily(Res.String.GENOS_REGULAR)
                        .color(Colors.DodgerBlue)
                        .fontSize(25.px),
                    variant = UncoloredLinkVariant
                )

                SpanText(
                    text = Res.String.COMING_SOON_2,
                    modifier = Modifier
                        .margin(bottom = 10.px)
                        .fontFamily(Res.String.GENOS_REGULAR)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .fontSize(25.px)
                )
            }
        }
    }
}