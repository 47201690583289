package id.gaenta.lp.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import id.gaenta.lp.CircleButtonVariant
import id.gaenta.lp.UncoloredButtonVariant
import id.gaenta.lp.util.Res
import id.gaenta.lp.util.Res.Dimens.BORDER_RADIUS
import org.jetbrains.compose.web.css.*

@Composable
fun IconButton(
    modifier: Modifier = Modifier,
    colorMode: ColorMode,
    link: String = "",
    icon: String,
    iconSize: CSSSizeValue<CSSUnit.px> = Res.Dimens.ICON_SIZE.px,
    onClick: (() -> Unit)? = null
) {
    Link(path = link) {
        Box(
            modifier = modifier
                .padding(all = 6.px)
                .borderRadius(r = BORDER_RADIUS.px)
                .cursor(Cursor.Pointer)
                .border(
                    width = 1.px,
                    style = LineStyle.Solid,
                    color = if (colorMode.isLight) Res.Theme.SOCIAL_ICON_BACKGROUND_LIGHT.color
                    else Res.Theme.SOCIAL_ICON_BACKGROUND_DARK.color
                )
                .onClick { onClick?.invoke() }
        ) {
            Image(
                modifier = Modifier.size(iconSize),
                src = icon
            )
        }
    }
}

enum class SocialIcon(
    val icon: String,
    val link: String
) {
    Linkedin(
        icon = Res.Icon.LINKEDIN,
        link = "https://www.linkedin.com/company/gaenta-sinergi-sukses"
    ),
    LinkedinLight(
        icon = Res.Icon.LINKEDIN_LIGHT,
        link = "https://www.linkedin.com/company/gaenta-sinergi-sukses"
    ),
/*    Facebook(
        icon = Res.Icon.FACEBOOK,
        link = ""
    ),
    FacebookLight(
        icon = Res.Icon.FACEBOOK_LIGHT,
        link = ""
    ),*/
    Instagram(
        icon = Res.Icon.INSTAGRAM,
        link = "https://www.instagram.com/gaenta.id"
    ),
    InstagramLight(
        icon = Res.Icon.INSTAGRAM_LIGHT,
        link = "https://www.instagram.com/gaenta.id"
    ),
    XTwitter(
        icon = Res.Icon.X,
        link = "https://x.com/gaentaid"
    ),
    XTwitterLight(
        icon = Res.Icon.X_LIGHT,
        link = "https://x.com/gaentaid"
    )
}

enum class GetInTouchIcon(
    val icon: String,
    val link: String
) {
    Mail(
        icon = Res.Icon.EMAIL,
        link = Res.String.EMAIL_GAENTA_MARKETING
    ),
    MailLight(
        icon = Res.Icon.EMAIL_LIGHT,
        link = Res.String.EMAIL_GAENTA_MARKETING
    ),
    WhatsApp(
        icon = Res.Icon.WHATSAPP,
        link = "https://wa.me/" + Res.String.WHATSAPP_NUMBER
    ),
    WhatsAppLight(
        icon = Res.Icon.WHATSAPP_LIGHT,
        link = "https://wa.me/628818228339"
    )
}

@Composable
fun IconButton(onClick: () -> Unit, content: @Composable () -> Unit) {
    Button(
        onClick = { onClick() },
        Modifier.setVariable(ButtonVars.FontSize, 1.em), // Make button icon size relative to parent container font size
        variant = CircleButtonVariant.then(UncoloredButtonVariant)
    ) {
        content()
    }
}
