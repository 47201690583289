package id.gaenta.lp.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun SiteMapPage() {
    id.gaenta.lp.components.layouts.MarkdownLayout("Site Map") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("site-map") }) {
            org.jetbrains.compose.web.dom.Text("Site Map")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H3(attrs = { id("home") }) {
            org.jetbrains.compose.web.dom.Text("Home")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.H4(attrs = { id("home-2") }) {
                    com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/\"") {
                        org.jetbrains.compose.web.dom.Text("Home")
                    }
                    org.jetbrains.compose.web.dom.Text(" ")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
            }
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H3(attrs = { id("products") }) {
            org.jetbrains.compose.web.dom.Text("Products")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.H4(attrs = { id("products-2") }) {
                    com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/products\"") {
                        org.jetbrains.compose.web.dom.Text("Products")
                    }
                    org.jetbrains.compose.web.dom.Text(" ")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
            }
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H3(attrs = { id("solutions") }) {
            org.jetbrains.compose.web.dom.Text("Solutions")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.H4(attrs = { id("solutions-2") }) {
                    com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/solutions\"") {
                        org.jetbrains.compose.web.dom.Text("Solutions")
                    }
                    org.jetbrains.compose.web.dom.Text(" ")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
            }
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H3(attrs = { id("support") }) {
            org.jetbrains.compose.web.dom.Text("Support")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.H4(attrs = { id("get-support") }) {
                    com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/support#get-support\"") {
                        org.jetbrains.compose.web.dom.Text("Get Support")
                    }
                    org.jetbrains.compose.web.dom.Text(" ")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.H4(attrs = { id("product-support") }) {
                    com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/support#product-support\"") {
                        org.jetbrains.compose.web.dom.Text("Product Support")
                    }
                    org.jetbrains.compose.web.dom.Text(" ")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.H4(attrs = { id("solution-support") }) {
                    com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/support#solution-support\"") {
                        org.jetbrains.compose.web.dom.Text("Solution Support")
                    }
                    org.jetbrains.compose.web.dom.Text(" ")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
            }
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H3(attrs = { id("gænta") }) {
            org.jetbrains.compose.web.dom.Text("Gænta")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.H4(attrs = { id("about-us") }) {
                    com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/gaenta#about-us\"") {
                        org.jetbrains.compose.web.dom.Text("About Us")
                    }
                    org.jetbrains.compose.web.dom.Text(" ")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.H4(attrs = { id("contact-us") }) {
                    com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/gaenta#contact-us\"") {
                        org.jetbrains.compose.web.dom.Text("Contact Us")
                    }
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.H4(attrs = { id("locations") }) {
                    com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/gaenta#locations\"") {
                        org.jetbrains.compose.web.dom.Text("Locations")
                    }
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
            }
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.H3(attrs = { id("privacy-policy") }) {
            org.jetbrains.compose.web.dom.Text("Privacy Policy")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.H4(attrs = { id("privacy-policy-2") }) {
                    com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/privacy-policy\"") {
                        org.jetbrains.compose.web.dom.Text("Privacy Policy")
                    }
                    org.jetbrains.compose.web.dom.Text(" ")
                    com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                }
            }
        }
    }
}
