package id.gaenta.lp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import id.gaenta.lp.components.layouts.PageLayout
import id.gaenta.lp.components.sections.Solutions
import id.gaenta.lp.util.Res

@Page("solutions")
@Composable
fun Solutions() {
    PageLayout(Res.String.SOLUTIONS) {
        val colorMode by ColorMode.currentState

        Solutions(colorMode = colorMode)
    }
}
