package id.gaenta.lp.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import id.gaenta.lp.components.widgets.IconButton
import id.gaenta.lp.styles.GetInTouchIconStyle
import id.gaenta.lp.util.Res
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Span

@Composable
fun Support(colorMode: ColorMode) {
    val breakpoint = rememberBreakpoint()
    /*Title*/
    SimpleGrid(
        numColumns = numColumns(base = 0, md = 1),
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint <= Breakpoint.MD) 100.percent
                else Res.Dimens.MAX_CARD_WIDTH.px
            )
            .thenIf(
                condition = breakpoint > Breakpoint.MD,
                other = Modifier.height(80.percent)
            )
            .boxShadow(
                color = Colors.Black.copy(alpha = 10),
                blurRadius = 50.px,
                spreadRadius = 50.px
            )
            .padding(all = 12.px)
            .borderRadius(r = Res.Dimens.BORDER_RADIUS.px)
            .background(
                if (colorMode.isLight)
                    Res.Theme.LIGHT_BLUE.color
                else
                    Res.Theme.DARK_BLUE.color
            )
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(all = 40.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = if (breakpoint <= Breakpoint.SM)
                Alignment.CenterHorizontally else Alignment.Start
        ) {
            /*Support Title*/
            SpanText(
                text = Res.String.SUPPORT,
                modifier = Modifier
                    .margin(bottom = 2.px)
                    .fontFamily(Res.String.POPPINS_REGULAR)
                    .color(
                        if (colorMode.isLight) Colors.Black
                        else Colors.WhiteSmoke
                    )
                    .fontSize(30.px)
                    .fontWeight(FontWeight.Bold)
                    .textAlign(
                        if (breakpoint <= Breakpoint.SM) TextAlign.Center
                        else TextAlign.Start
                    )
            )
        }
    }

    Br()

    /*Get Support*/
    SimpleGrid(
        numColumns = numColumns(base = 0, md = 1),
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint <= Breakpoint.MD) 100.percent
                else Res.Dimens.MAX_CARD_WIDTH.px
            )
            .thenIf(
                condition = breakpoint > Breakpoint.MD,
                other = Modifier.height(80.percent)
            )
            .boxShadow(
                color = Colors.Black.copy(alpha = 10),
                blurRadius = 50.px,
                spreadRadius = 50.px
            )
            .padding(all = 12.px)
            .borderRadius(r = Res.Dimens.BORDER_RADIUS.px)
            .background(
                if (colorMode.isLight)
                    Res.Theme.LIGHT_BLUE.color
                else
                    Res.Theme.DARK_BLUE.color
            )
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(all = 40.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = if (breakpoint <= Breakpoint.SM)
                Alignment.CenterHorizontally else Alignment.Start
        ) {
            /*Get Support Content*/
            Span {
                SpanText(
                    text = Res.String.GET_SUPPORT,
                    modifier = Modifier
                        .id("get-support")
                        .margin(bottom = 10.px)
                        .fontFamily(Res.String.GENOS_REGULAR)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .fontSize(25.px)
                )

                Br()

                SpanText(
                    text = Res.String.GET_SUPPORT_DESC,
                    modifier = Modifier
                        .fontFamily(Res.String.POPPINS_REGULAR)
                        .fontSize(14.px)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .opacity(75.percent)
                        .lineHeight(2)
                        .margin(bottom = 100.px)
                )

                Br()

                Span {
                    Row(
                        Modifier
                            .padding(bottom = 5.px)
                    ) {
                        IconButton(
                            modifier = GetInTouchIconStyle.toModifier(),
                            colorMode = colorMode,
                            icon = if (colorMode.isLight) Res.Icon.EMAIL
                            else Res.Icon.EMAIL_LIGHT,
                            link = Res.String.EMAIL_GAENTA_GET_HELP
                        )
                        SpanText(
                            text = Res.String.MAIL_ADDRESS_GET_HELP,
                            modifier = Modifier
                                .fontFamily(Res.String.POPPINS_REGULAR)
                                .fontSize(14.px)
                                .color(
                                    if (colorMode.isLight) Colors.Black
                                    else Colors.WhiteSmoke
                                )
                                .opacity(75.percent)
                                .lineHeight(2)
                                .padding(left = 4.px)
                                .textAlign(
                                    if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                    else TextAlign.Start
                                )
                        )
                    }

                    Row {
                        IconButton(
                            modifier = GetInTouchIconStyle.toModifier(),
                            colorMode = colorMode,
                            icon = if (colorMode.isLight) Res.Icon.WHATSAPP
                            else Res.Icon.WHATSAPP_LIGHT,
                            link = Res.String.WHATSAPP_LINK
                        )
                        SpanText(
                            text = Res.String.WHATSAPP_NUMBER,
                            modifier = Modifier
                                .fontFamily(Res.String.POPPINS_REGULAR)
                                .fontSize(14.px)
                                .color(
                                    if (colorMode.isLight) Colors.Black
                                    else Colors.WhiteSmoke
                                )
                                .opacity(75.percent)
                                .lineHeight(2)
                                .padding(left = 4.px)
                                .textAlign(
                                    if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                    else TextAlign.Start
                                )
                        )
                    }
                }
            }
        }
    }

    Br()

    /*Product Support*/
    SimpleGrid(
        numColumns = numColumns(base = 0, md = 1),
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint <= Breakpoint.MD) 100.percent
                else Res.Dimens.MAX_CARD_WIDTH.px
            )
            .thenIf(
                condition = breakpoint > Breakpoint.MD,
                other = Modifier.height(80.percent)
            )
            .boxShadow(
                color = Colors.Black.copy(alpha = 10),
                blurRadius = 50.px,
                spreadRadius = 50.px
            )
            .padding(all = 12.px)
            .borderRadius(r = Res.Dimens.BORDER_RADIUS.px)
            .background(
                if (colorMode.isLight)
                    Res.Theme.LIGHT_BLUE.color
                else
                    Res.Theme.DARK_BLUE.color
            )
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(all = 40.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = if (breakpoint <= Breakpoint.SM)
                Alignment.CenterHorizontally else Alignment.Start
        ) {
            /*Product Support Content*/
            Span {
                SpanText(
                    text = Res.String.PRODUCT_SUPPORT,
                    modifier = Modifier
                        .id("product-support")
                        .margin(bottom = 10.px)
                        .fontFamily(Res.String.GENOS_REGULAR)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .fontSize(25.px)
                )

                Br()

                SpanText(
                    text = Res.String.PRODUCT_SUPPORT_DESC,
                    modifier = Modifier
                        .fontFamily(Res.String.POPPINS_REGULAR)
                        .fontSize(14.px)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .opacity(75.percent)
                        .lineHeight(2)
                        .margin(bottom = 100.px)
                )

                Br()

                Span {
                    Row(
                        Modifier
                            .padding(bottom = 5.px)
                    ) {
                        IconButton(
                            modifier = GetInTouchIconStyle.toModifier(),
                            colorMode = colorMode,
                            icon = if (colorMode.isLight) Res.Icon.EMAIL
                            else Res.Icon.EMAIL_LIGHT,
                            link = Res.String.EMAIL_GAENTA_PRODUCTS
                        )
                        SpanText(
                            text = Res.String.MAIL_ADDRESS_PRODUCTS,
                            modifier = Modifier
                                .fontFamily(Res.String.POPPINS_REGULAR)
                                .fontSize(14.px)
                                .color(
                                    if (colorMode.isLight) Colors.Black
                                    else Colors.WhiteSmoke
                                )
                                .opacity(75.percent)
                                .lineHeight(2)
                                .padding(left = 4.px)
                                .textAlign(
                                    if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                    else TextAlign.Start
                                )
                        )
                    }
                }
            }
        }
    }

    Br()

    /*Solution*/
    SimpleGrid(
        numColumns = numColumns(base = 0, md = 1),
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint <= Breakpoint.MD) 100.percent
                else Res.Dimens.MAX_CARD_WIDTH.px
            )
            .thenIf(
                condition = breakpoint > Breakpoint.MD,
                other = Modifier.height(80.percent)
            )
            .boxShadow(
                color = Colors.Black.copy(alpha = 10),
                blurRadius = 50.px,
                spreadRadius = 50.px
            )
            .padding(all = 12.px)
            .borderRadius(r = Res.Dimens.BORDER_RADIUS.px)
            .background(
                if (colorMode.isLight)
                    Res.Theme.LIGHT_BLUE.color
                else
                    Res.Theme.DARK_BLUE.color
            )
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(all = 40.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = if (breakpoint <= Breakpoint.SM)
                Alignment.CenterHorizontally else Alignment.Start
        ) {
            /*Solution Support Content*/
            Span {
                SpanText(
                    text = Res.String.SOLUTION_SUPPORT,
                    modifier = Modifier
                        .id("solution-support")
                        .margin(bottom = 10.px)
                        .fontFamily(Res.String.GENOS_REGULAR)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .fontSize(25.px)
                )

                Br()

                SpanText(
                    text = Res.String.SOLUTION_SUPPORT_DESC,
                    modifier = Modifier
                        .fontFamily(Res.String.POPPINS_REGULAR)
                        .fontSize(14.px)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .opacity(75.percent)
                        .lineHeight(2)
                        .margin(bottom = 100.px)
                )

                Br()

                Span {
                    Row(
                        Modifier
                            .padding(bottom = 5.px)
                    ) {
                        IconButton(
                            modifier = GetInTouchIconStyle.toModifier(),
                            colorMode = colorMode,
                            icon = if (colorMode.isLight) Res.Icon.EMAIL
                            else Res.Icon.EMAIL_LIGHT,
                            link = Res.String.EMAIL_GAENTA_SOLUTIONS
                        )
                        SpanText(
                            text = Res.String.MAIL_ADDRESS_SOLUTIONS,
                            modifier = Modifier
                                .fontFamily(Res.String.POPPINS_REGULAR)
                                .fontSize(14.px)
                                .color(
                                    if (colorMode.isLight) Colors.Black
                                    else Colors.WhiteSmoke
                                )
                                .opacity(75.percent)
                                .lineHeight(2)
                                .padding(left = 4.px)
                                .textAlign(
                                    if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                    else TextAlign.Start
                                )
                        )
                    }
                }
            }
        }
    }
}