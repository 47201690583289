package id.gaenta.lp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import id.gaenta.lp.components.layouts.PageLayout
import id.gaenta.lp.components.sections.Gaenta

@Page("gaenta")
@Composable
fun Gaenta() {
    PageLayout("About") {
        val colorMode by ColorMode.currentState

        Gaenta(colorMode = colorMode)
    }
}
