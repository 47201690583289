package id.gaenta.lp.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun PrivacyPolicyPage() {
    id.gaenta.lp.components.layouts.MarkdownLayout("Privacy Police") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("privacy-policy") }) {
            org.jetbrains.compose.web.dom.Text("Privacy Policy")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Coming soon! Please refer to official ")
            com.varabyte.kobweb.compose.dom.GenericTag("a", "href=\"/support\"") {
                org.jetbrains.compose.web.dom.Text("Support")
            }
            org.jetbrains.compose.web.dom.Text(" until this page is populated.")
        }
    }
}
