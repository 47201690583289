package id.gaenta.lp.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import id.gaenta.lp.components.widgets.IconButton
import id.gaenta.lp.styles.GetInTouchIconStyle
import id.gaenta.lp.util.Res
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Span

@Composable
fun Gaenta(colorMode: ColorMode) {
    val breakpoint = rememberBreakpoint()

    SimpleGrid(
        numColumns = numColumns(base = 0, md = 1),
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint <= Breakpoint.MD) 100.percent
                else Res.Dimens.MAX_CARD_WIDTH.px
            )
            .thenIf(
                condition = breakpoint > Breakpoint.MD,
                other = Modifier.height(95.percent)
            )
            .boxShadow(
                color = Colors.Black.copy(alpha = 10),
                blurRadius = 50.px,
                spreadRadius = 50.px
            )
            .padding(all = 12.px)
            .borderRadius(r = Res.Dimens.BORDER_RADIUS.px)
            .background(
                if (colorMode.isLight)
                    Res.Theme.LIGHT_BLUE.color
                else
                    Res.Theme.DARK_BLUE.color
            )
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(all = 40.px),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = if (breakpoint <= Breakpoint.SM)
                Alignment.CenterHorizontally else Alignment.Start
        ) {
            /*About Us Title*/
            SpanText(
                text = Res.String.ABOUT_US,
                modifier = Modifier
                    .id("about-us")
                    .margin(bottom = 2.px)
                    .fontFamily(Res.String.POPPINS_REGULAR)
                    .color(
                        if (colorMode.isLight) Colors.Black
                        else Colors.WhiteSmoke
                    )
                    .fontSize(30.px)
                    .fontWeight(FontWeight.Bold)
                    .textAlign(
                        if (breakpoint <= Breakpoint.SM) TextAlign.Center
                        else TextAlign.Start
                    )
            )

            /*About Us Content*/
            Span {
                SpanText(
                    text = Res.String.COMPANY_FULL,
                    modifier = Modifier
                        .margin(bottom = 10.px)
                        .fontFamily(Res.String.GENOS_REGULAR)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .fontSize(25.px)
                )

                Br()

                SpanText(
                    text = "―Call Name: " + Res.String.CALL_NAME_1 + "/" + Res.String.CALL_NAME_2,
                    modifier = Modifier
                        .margin(bottom = 24.px)
                        .fontFamily(Res.String.COURIER_PRIME_REGULAR)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .fontSize(12.px)
                )

                Br()

                SpanText(
                    text = Res.String.VISION,
                    modifier = Modifier
                        .fontFamily(Res.String.POPPINS_REGULAR)
                        .fontSize(14.px)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .opacity(75.percent)
                        .lineHeight(2)
                        .margin(bottom = 36.px)
                )


                Br()

                SpanText(
                    text = Res.String.MISSION,
                    modifier = Modifier
                        .fontFamily(Res.String.POPPINS_REGULAR)
                        .fontSize(14.px)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .opacity(75.percent)
                        .lineHeight(2)
                        .margin(bottom = 36.px)
                        .textAlign(
                            if (breakpoint <= Breakpoint.SM) TextAlign.Center
                            else TextAlign.Start
                        )
                )

                Br()

                Li {
                    SpanText(
                        text = Res.String.VALUES_1,
                        modifier = Modifier
                            .fontFamily(Res.String.POPPINS_REGULAR)
                            .fontSize(14.px)
                            .color(
                                if (colorMode.isLight) Colors.Black
                                else Colors.WhiteSmoke
                            )
                            .opacity(75.percent)
                            .lineHeight(2)
                            .margin(bottom = 36.px)
                            .textAlign(
                                if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                else TextAlign.Start
                            )
                    )
                }

                Li {
                    SpanText(
                        text = Res.String.VALUES_2,
                        modifier = Modifier
                            .fontFamily(Res.String.POPPINS_REGULAR)
                            .fontSize(14.px)
                            .color(
                                if (colorMode.isLight) Colors.Black
                                else Colors.WhiteSmoke
                            )
                            .opacity(75.percent)
                            .lineHeight(2)
                            .margin(bottom = 36.px)
                            .textAlign(
                                if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                else TextAlign.Start
                            )
                    )
                }

                Li {
                    SpanText(
                        text = Res.String.VALUES_3,
                        modifier = Modifier
                            .fontFamily(Res.String.POPPINS_REGULAR)
                            .fontSize(14.px)
                            .color(
                                if (colorMode.isLight) Colors.Black
                                else Colors.WhiteSmoke
                            )
                            .opacity(75.percent)
                            .lineHeight(2)
                            .margin(bottom = 36.px)
                            .textAlign(
                                if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                else TextAlign.Start
                            )
                    )
                }

                Li {
                    SpanText(
                        text = Res.String.VALUES_4,
                        modifier = Modifier
                            .fontFamily(Res.String.POPPINS_REGULAR)
                            .fontSize(14.px)
                            .color(
                                if (colorMode.isLight) Colors.Black
                                else Colors.WhiteSmoke
                            )
                            .opacity(75.percent)
                            .lineHeight(2)
                            .margin(bottom = 36.px)
                            .textAlign(
                                if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                else TextAlign.Start
                            )
                    )
                }
            }

            Br()

            /*Contact Us Title*/
            SpanText(
                text = Res.String.CONTACT_US,
                modifier = Modifier
                    .id("contact-us")
                    .margin(bottom = 2.px)
                    .fontFamily(Res.String.POPPINS_REGULAR)
                    .color(
                        if (colorMode.isLight) Colors.Black
                        else Colors.WhiteSmoke
                    )
                    .fontSize(30.px)
                    .fontWeight(FontWeight.Bold)
                    .textAlign(
                        if (breakpoint <= Breakpoint.SM) TextAlign.Center
                        else TextAlign.Start
                    )
            )

            /*Contact Us Content*/
            Span {
                Row(
                    Modifier
                        .padding(bottom = 5.px)
                ) {
                    IconButton(
                        modifier = GetInTouchIconStyle.toModifier(),
                        colorMode = colorMode,
                        icon = if (colorMode.isLight) Res.Icon.EMAIL
                        else Res.Icon.EMAIL_LIGHT,
                        link = Res.String.EMAIL_GAENTA_MARKETING
                    )
                    SpanText(
                        text = Res.String.MAIL_ADDRESS_MARKETING,
                        modifier = Modifier
                            .fontFamily(Res.String.POPPINS_REGULAR)
                            .fontSize(14.px)
                            .color(
                                if (colorMode.isLight) Colors.Black
                                else Colors.WhiteSmoke
                            )
                            .opacity(75.percent)
                            .lineHeight(2)
                            .padding(left = 4.px)
                            .textAlign(
                                if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                else TextAlign.Start
                            )
                    )
                }

                Row {
                    IconButton(
                        modifier = GetInTouchIconStyle.toModifier(),
                        colorMode = colorMode,
                        icon = if (colorMode.isLight) Res.Icon.WHATSAPP
                        else Res.Icon.WHATSAPP_LIGHT,
                        link = Res.String.WHATSAPP_LINK
                    )
                    SpanText(
                        text = Res.String.WHATSAPP_NUMBER,
                        modifier = Modifier
                            .fontFamily(Res.String.POPPINS_REGULAR)
                            .fontSize(14.px)
                            .color(
                                if (colorMode.isLight) Colors.Black
                                else Colors.WhiteSmoke
                            )
                            .opacity(75.percent)
                            .lineHeight(2)
                            .padding(left = 4.px)
                            .textAlign(
                                if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                else TextAlign.Start
                            )
                    )
                }
            }

            Br()

            /*Locations Title*/
            SpanText(
                text = Res.String.LOCATIONS,
                modifier = Modifier
                    .id("locations")
                    .margin(bottom = 2.px)
                    .fontFamily(Res.String.POPPINS_REGULAR)
                    .color(
                        if (colorMode.isLight) Colors.Black
                        else Colors.WhiteSmoke
                    )
                    .fontSize(30.px)
                    .fontWeight(FontWeight.Bold)
                    .textAlign(
                        if (breakpoint <= Breakpoint.SM) TextAlign.Center
                        else TextAlign.Start
                    )
            )

            /*Locations Content*/
            Span{
                Row {
                    IconButton(
                        modifier = GetInTouchIconStyle.toModifier(),
                        colorMode = colorMode,
                        icon = if (colorMode.isLight) Res.Icon.GMAPS
                        else Res.Icon.GMAPS_LIGHT,
                        link = Res.String.LOC_JAKARTA_GMAPS
                    )
                    SpanText(
                        text = Res.String.LOC_JAKARTA,
                        modifier = Modifier
                            .fontFamily(Res.String.POPPINS_REGULAR)
                            .fontSize(14.px)
                            .color(
                                if (colorMode.isLight) Colors.Black
                                else Colors.WhiteSmoke
                            )
                            .opacity(75.percent)
                            .lineHeight(2)
                            .padding(left = 4.px)
                            .textAlign(
                                if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                else TextAlign.Start
                            )
                    )
                }

                SpanText(
                    text = Res.String.LOC_JAKARTA_STREET,
                    modifier = Modifier
                        .fontFamily(Res.String.POPPINS_REGULAR)
                        .fontSize(14.px)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .opacity(75.percent)
                        .lineHeight(2)
                        .margin(bottom = 36.px)
                        .textAlign(
                            if (breakpoint <= Breakpoint.SM) TextAlign.Center
                            else TextAlign.Start
                        )
                )

                Br()

                Row {
                    IconButton(
                        modifier = GetInTouchIconStyle.toModifier(),
                        colorMode = colorMode,
                        icon = if (colorMode.isLight) Res.Icon.GMAPS
                        else Res.Icon.GMAPS_LIGHT,
                        link = Res.String.LOC_BANDUNG_GMAPS
                    )
                    SpanText(
                        text = Res.String.LOC_BANDUNG,
                        modifier = Modifier
                            .fontFamily(Res.String.POPPINS_REGULAR)
                            .fontSize(14.px)
                            .color(
                                if (colorMode.isLight) Colors.Black
                                else Colors.WhiteSmoke
                            )
                            .opacity(75.percent)
                            .lineHeight(2)
                            .padding(left = 4.px)
                            .textAlign(
                                if (breakpoint <= Breakpoint.SM) TextAlign.Center
                                else TextAlign.Start
                            )
                    )
                }

                SpanText(
                    text = Res.String.LOC_BANDUNG_STREET,
                    modifier = Modifier
                        .fontFamily(Res.String.POPPINS_REGULAR)
                        .fontSize(14.px)
                        .color(
                            if (colorMode.isLight) Colors.Black
                            else Colors.WhiteSmoke
                        )
                        .opacity(75.percent)
                        .lineHeight(2)
                        .margin(bottom = 36.px)
                        .textAlign(
                            if (breakpoint <= Breakpoint.SM) TextAlign.Center
                            else TextAlign.Start
                        )
                )
            }
        }
    }
}