package id.gaenta.lp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import id.gaenta.lp.components.layouts.PageLayout
import id.gaenta.lp.components.sections.Support
import id.gaenta.lp.util.Res

@Page("support")
@Composable
fun Support() {
    PageLayout("Official " + Res.String.SUPPORT) {
        val colorMode by ColorMode.currentState

        Support(colorMode = colorMode)
    }
}
